
import { defineComponent, computed, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmDropdownFilter,
  },
  props: {
    selected: {
      type: Array,
    },
    itemName: {
      type: String,
      default: 'contact',
    },
    fields: {
      type: Array,
      required: true,
    },
    initialFields: {
      type: Array,
    },
    singleField: {
      type: Boolean,
    },
    btnText: {
      type: String,
    },
    customTitle: {
      type: String,
    },
    addLabel: {
      type: String,
      default: 'Add another field',
    },
  },
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const state = ref(props.initialFields ? props.initialFields : [])
    const unselectedFields = computed(() => {
      const uidsSelected = state.value.map((el: any) => el.uid)
      const unselected = props.fields.filter((el: any) => !uidsSelected.includes(el.uid))

      return unselected
    })

    return {
      isSmMax,
      unselectedFields,
      state,
    }
  },
})
